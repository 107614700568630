<template>
    <div
        class="marketing-bar"
        :class="break3 ? 'd-flex justify-content-between border-0' : ''"
    >
        <img
            class="logo_class border-0"
            @click="$router.push({name: 'Home'})"
            src="@/assets/logo.png"
            :class="logoClass"
            v-if="break3"
        />
        <h2
            class="my-auto font-weight-normal text-primary text-center"
            :class="promoBannerTextClass"
        >
            File Now and Take 10% Off – Enter Coupon Code
            <span class="strong-text-1"> GET10-OFF</span> at Checkout
        </h2>
        <div class="px-2" v-if="break3"></div>
    </div>
</template>

<script>
const BREAK_0 = 1200;
const BREAK_1 = 880;
const BREAK_2 = 840;
const BREAK_3 = 744;

export default {
    computed: {
        break3() {
            return this.$windowWidth > BREAK_3;
        },
        promoBannerTextClass() {
            if (this.$windowWidth > BREAK_0) {
                return 'h2';
            } else if (this.$windowWidth > BREAK_2) {
                return 'h3';
            } else if (this.$windowWidth > BREAK_3) {
                return 'h4';
            }
            else return 'h4';
        },
        logoClass() {
            if (this.$windowWidth > BREAK_1) {
                return '';
            } else {
                return 'logo_class_small_1';
            }
        },
    },
};
</script>

<style scoped>
.marketing-bar {
    position: relative;
    z-index: 99999;
    background-color: #49484e;
    width: inherit;
    max-width: inherit;
    /* height: auto; */
    border-width: 0px !important;
    top: 0;
    /* border-bottom-color: rgb(225, 225, 225);
	border-bottom-width: 1.5px;
	border-bottom-style: solid; */
}
.logo_class {
    cursor: pointer;
    padding: 3px 26px 3px 6px;
    height: 56px;
    /* position: absolute; */
    background-color: white;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%);
    border-width: 0px !important;
}
.logo_class_small_1 {
    height: 40px;
}
</style>
