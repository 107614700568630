<template>
    <div class="font-app">
        <div class="card w-100">
            <div class="d-flex justify-content-end p-2 mb-1">
                <btn
                    @click="$router.push({ name: 'Login' })"
                    color="info"
                    app
                    size="sm"
                >
                    <i class="fas fa-lock mr-2" /> Sign In
                </btn>
            </div>
            <div class="card-body px-5 pb-5 pt-0 w-100">
                <SignupBase
                    header-text="Create an Account"
                    button-text="START NOW"
                    :sign-in-option="false"
                    show-confirmation-message
                    extra-links-class="landing-font"
                />
                <p class="mt-2 text-center">
                    Thriftax will <u>never</u> sell your info!
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import SignupBase from '@/views/auth/signup_base';

export default {
    components: {
        SignupBase,
    },
};
</script>
