<template>
    <div class="row mx-0">
        <div
            v-for="(item, index) in items"
            :key="index"
            class="col col-12 col-lg mb-3"
        >
            <div
                class="card clean_card h-100"
                data-aos="fade-up"
                :data-aos-delay="100 * index"
            >
                <div class="card-body">
                    <p>
                        {{ item.content }}
                    </p>
                </div>
                <div class="card-footer mt-0 pt-0 border-0">
                    <h4>{{ item.name }}</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            items: [
                {
                    content: `I had experience with other Nonresident tax software but
                        with Thriftax the experience was truly butter-smooth,
                        simple and even fun!`,
                    name: `Akash G. (USC Student)`,
                },
                {
                    content: `
                        Filing my taxes with Thriftax was super intuitive.
                        Thanks to the helpful hints I was able to easily answer
                        even the challenging questions. Just got my refund last
                        week, thank you Thriftax!`,
                    name: `Adi D. (UCLA Scholar)`,
                },
                {
                    content: `
                        Thriftax not only gave me the tax return I deserved, it
                        even identified that I am eligible for a social security
                        tax return, which is an extra sweet $908! Highly
                        recommended!`,
                    name: `Yudai K. (UT Austin Student)`,
                },
                {
                    content: `
                        I honestly expected the process to take a few hours, I
                        was able to complete the entire process in less than 20
                        mins. I’d file with you guys again next year.`,
                    name: `Omar A. (University of Miami)`,
                },
            ],
        };
    },
};
</script>
