<template>
    <div class="hero-max-width mx-auto position-relative">
        <PromoBanner />
        <div class="row mx-0 d-flex position-relative px-3">
            <div class="mb-0 pb-0 pt-2" :class="maxBreak ? 'col-9' : 'col-12'">
                <div class="row">
                    <!-- <ThreeStep
						class="col-5 pb-0 mb-0 order-1"
						v-else-if="smallBreak"
						always-vertical
						without-subtext
						step-size="md"
						sub-header-class="h5"
						header-class="h3"
						icon-size="7"
						margin-classes="mb-2 bs-mb-3"
					/> -->
                    <div
                        class="mb-0 pb-0 order-0 px-0 pr-2"
                        :class="heroBodyClasses"
                    >
                        <div
                            v-if="!smallBreak"
                            class="mb-3 text-center"
                            @click="$router.push({ name: 'Home' })"
                        >
                            <router-link :to="{ name: 'Home' }">
                                <img
                                    class="img-fluid w-40 bs-pb-3"
                                    alt="logo"
                                    src="@/assets/logo.png"
                                />
                            </router-link>
                        </div>
                        <div>
                            <h2
                                class="mb-4"
                                :class="{ 'text-center': !smallBreak }"
                            >
                                ONLINE TAX FILING FOR NON-RESIDENTS
                            </h2>
                            <HeroBullets />
                            <ReadyToFileBanner v-if="!smallBreak" no-header />
                        </div>
                        <ThreeStep
                            v-if="medBreak"
                            header-text="How It Works"
                            without-subtext
                            step-size="md"
                            step-break-point="sm"
                            :sub-header-class="medBreak ? 'h5' : 'h6'"
                            header-class="h3"
                            icon-size="7"
                            step-item-class="px-3"
                            margin-classes="mb-2"
                        />
                    </div>
                    <div class="col-5 p-0 mb-3 mt-2" v-if="smallBreak">
                        <LandingSignup class="d-flex justify-content-end" />
                    </div>
                    <!-- <div class="card mb-3  signup_card">
						<div class="card-body">
							<SignupBase
								header-text="Create an Account"
								button-text="START NOW"
								:sign-in-option="false"
								show-confirmation-message
								extra-links-class="landing-font"
							/>
							<p class="mt-2 text-center text-muted">
								Thriftax will <u>never</u> sell your info!
							</p>
						</div>
					</div> -->
                    <!-- <div class="pb-0 mb-0 " :class="signupClasses" v-if="smallBreak">
						<div class="d-flex justify-content-end mb-4 mt-1">
						</div>
						<div class="card mb-3 ">
							<div class="card-body">
								<SignupBase
									header-text="Create an Account"
									button-text="START NOW"
									:sign-in-option="false"
									show-confirmation-message
									extra-links-class="landing-font"
								/>
								<p class="mt-2 text-center text-muted">
									Thriftax will <u>never</u> sell your info!
								</p>
							</div>
						</div>
					</div> -->
                    <ThreeStep
                        v-if="!medBreak"
                        header-text="How It Works"
                        without-subtext
                        step-size="md"
                        step-break-point="sm"
                        :sub-header-class="medBreak ? 'h5' : 'h6'"
                        header-class="h3"
                        icon-size="7"
                        step-item-class="px-3"
                        margin-classes="mb-2"
                        class="my-3 mx-auto"
                    />
                </div>
            </div>
            <!-- <LandingSignup
				v-if="maxBreak"
				class="mb-3"
				:class="{
					signup_card_compact_2: $windowWidth < 1350,
					signup_card_compact_1: $windowWidth < 1450,
				}"
			/> -->
            <div :class="coverageMessageClass">
                <div class="card">
                    <div class="card-body p-3">
                        <CoverageMessage class="larger_helper_text" />
                    </div>
                </div>
            </div>

            <div class="img_container" v-if="maxBreak"></div>
        </div>

        <div class="px-4">
            <pricing-cards class="space-top-2 mb-5" show-onlys start-filing-buttons />

            <h2 class="text-center">Actual Customer Testimonials</h2>
            <testimonials class="mb-4" />

            <p class="text-center small">
                <strong>
                    Note: Are you a non-resident alien? Know that you cannot
                    file with TurboTax!
                </strong>
                <br />
                As a non-resident alien you are required to file form 1040-NR,
                rather than form 1040 offered by tax return solutions for
                residents such as TurboTax, H&R Block etc.
                <br />
                Thriftax has got your non-resident tax filing needs covered for
                better prices and user experience (yes, better than Sprintax and
                Glacier tax!). File with Thriftax today.
            </p>
        </div>
        <t-footer />
    </div>
</template>

<script>
import SignupBase from '@/views/auth/signup_base';
import ThreeStep from '@/components/landings/three-step';
import CoverageMessage from '@/components/landings/coverage-message';
import IntroVideo from '@/components/landings/intro-video';
import HeroBullets from '@/components/landings/hero-bullets';
import Testimonials from '@/components/landings/testimonials';
import ReadyToFileBanner from '@/components/landings/ready-to-file-banner';
import PromoBanner from '@/components/landings/promo-banner';
import PricingCards from '@/components/landings/pricing-cards';
import LandingSignup from '@/components/landings/landing-signup';
import AOS from 'aos';
import { FEDERAL_PRICE, STATE_PRICE, FORM_8843_PRICE } from '@/constants';

export default {
    data() {
        return {
            STATE_PRICE: STATE_PRICE,
            FEDERAL_PRICE: FEDERAL_PRICE,
            FORM_8843_PRICE: FORM_8843_PRICE,
        };
    },
    computed: {
        maxBreak() {
            return this.$windowWidth > 1180;
        },
        xlBreak() {
            return this.$windowWidth > 1345;
        },
        medBreak() {
            return this.$windowWidth > 860;
        },
        smallBreak() {
            return this.$windowWidth > 860;
        },
        largeMarginCoverageMessageBreak() {
            return this.$windowWidth > 1400;
        },

        secondBannerInline() {
            return this.$windowWidth > 1050;
        },
        heroBodyClasses() {
            var classes = [];
            if (this.smallBreak) {
                classes.push('col-7 pr-3');
            }

            // if (this.xlBreak) {
            // 	classes.push("col-9 pr-4");
            // } else if (this.maxBreak) {
            // 	classes.push("col-8 pr-4");
            // } else if (this.medBreak) {
            // 	classes.push("col-7");
            // } else if (this.smallBreak) {
            // 	classes.push("col-6");
            // } else {
            // 	classes.push("col-12 mt-2");
            // }
            // if (this.medBreak) {
            // 	classes.push("pl-5 pr-5");
            // }
            return classes;
        },
        heroBulletsClasses() {
            var classes = [];

            if (!this.secondBannerInline) {
                classes.push('col-12');
            } else {
                classes.push('pr-7');
            }

            if (this.smallBreak) {
                classes.push('px-4');
            } else {
                classes.push('px-0');
            }

            return classes;
        },
        signupClasses() {
            var classes = [];
            if (!this.medBreak || !this.xlBreak) {
                classes.push('col-6');
            } else {
                classes.push('col-5');
            }
            if (this.medBreak) {
                classes.push('px-6');
            }
            return classes;
        },
        logoClass() {
            if (this.medBreak) {
                return 'w-25';
            } else {
                return 'w-30';
            }
        },
        landingSignupClass() {
            if (this.medBreak) {
                return 'col-5 pr-5';
            }
            return 'col-6 pr-3';
        },
        coverageMessageClass() {
            var classes = [];
            this.largeMarginCoverageMessageBreak
                ? classes.push('mt-7')
                : classes.push('mt-3');
            if (this.maxBreak) {
                classes.push('w-70 pr-3');
            }

            return classes;
        },
    },
    components: {
        SignupBase,
        ThreeStep,
        CoverageMessage,
        IntroVideo,
        HeroBullets,
        Testimonials,
        ReadyToFileBanner,
        PromoBanner,
        PricingCards,
        LandingSignup,
    },
    mounted() {
        AOS.init({
            once: true,
        });
    },
};
</script>

<style scoped>
.img_container {
    position: absolute;
    width: 28.5%;
    height: 100%;
    background-color: gray;
    right: 0;
    z-index: -1;
    background-image: url('~@/assets/img/lady_computer.jpg');
    background-repeat: no-repeat;
    background-position: right top;
    background-size: cover;
}

.signup_card {
    position: absolute;
    right: 27%;
    top: 80px;
    z-index: 9999;
}
.signup_card_compact_1 {
    right: 24%;
}

.signup_card_compact_2 {
    right: 22%;
}

.image-right {
    width: auto;
    height: 100%;
    object-fit: cover;
}

.img-col {
    overflow: hidden;
}

.col-container {
    /* max-height: 110vh; */
    display: flex;
    flex-direction: column !important;
}
.main-container {
    max-height: 95vh;
}

.hero-max-width {
    max-width: 1550px;
}
</style>
